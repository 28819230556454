/** @jsx jsx */
import { jsx } from "theme-ui"
import { FaCity as IconLocationList } from "react-icons/fa"
import { GoLocation as IconLocationElement } from "react-icons/go"

const locationElements = [
  "Kadıköy",
  "Erenköy",
  "Moda",
  "Fenerbahçe",
  "Feneryolu",
  "Göztepe",
  "Caddebostan",
  "Suadiye",
  "Bostancı",
  "Kozyatağı",
  "İçerenköy",
  "Ataşehir",
  "Küçükyalı",
  "Maltepe",
]

const ServiceLocations = () => {
  return (
    <div className="home-banner">
      <div>
        <div className="section-title-container">
          <div className="section-title-border" />
          <h1
            className="section-title-content"
            sx={{
              color: "muted",
            }}
          >
            Servis Bölgelerimiz
            <IconLocationList
              className="section-title-icon"
              color="rgb(96, 125, 139)"
            />
          </h1>
          <div className="section-title-border" />
        </div>
        <div className="section-content-list">
          <ul>
            {locationElements.map(text => (
              <li key={text}>
                <IconLocationElement color="orange" className="icon" />
                <p style={{ display: "inline" }}>{text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ServiceLocations
