/** @jsx jsx */
import { jsx } from "theme-ui"
import { FcSupport as IconServicesCollection } from "react-icons/fc"
import { TiKeyOutline as IconServiceElement } from "react-icons/ti"

const serviceElements = [
  "Kale Alarm Bayii",
  "Kale Kilit Bayii",
  "Dormakaba Bayii",
  "Keso Barel Bayii",
  "Alarm Sistemleri",
  "Kilit Sistemleri",
  "Multlock Key Center",
  "Master Barel Sistemleri",
  "Anahtar Çoğaltma",
  "Çilingir",
  "Göbek Değişimi",
  "Barel Değişimi",
  "Kilit Değişimi",
  "Garaj Kepenk Kumandası",
  "Geçiş Kontrol Uniteleri",
]

const Services = () => {
  return (
    <div className="home-banner">
      <div>
        <div className="section-title-container">
          <div className="section-title-border" />
          <h1
            className="section-title-content"
            sx={{
              color: "muted",
            }}
          >
            Hizmetlerimiz
            <IconServicesCollection className="section-title-icon" />
          </h1>
          <div className="section-title-border" />
        </div>
        <div className="section-content-list">
          <ul>
            {serviceElements.map(text => (
              <li key={text}>
                <IconServiceElement color="orange" className="icon" />
                <p style={{ display: "inline" }}>{text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Services
