/** @jsx jsx */
import { jsx } from "theme-ui"
import { SiGooglemaps as IconLocation } from "react-icons/si"

const GoogleMapEmbed = () => {
  return (
    <div className="home-banner">
      <div>
        <div className="section-title-container">
          <div className="section-title-border" />
          <h1
            className="section-title-content"
            sx={{
              color: "muted",
            }}
          >
            Konum
            <IconLocation className="section-title-icon" />
          </h1>
          <div className="section-title-border" />
        </div>
        <div className="section-content-list">
        <iframe 
        title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d188.6794724776207!2d29.074870038130104!3d40.97404663584923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac7a38865212f%3A0xd18059289e4ea538!2sCastle%20Lock!5e0!3m2!1sen!2str!4v1618079311040!5m2!1sen!2str"           
          width="100%"
          height="450px"
          style={{ border: "0" }}
          allowFullScreen
          loading="lazy">
            
          </iframe>
        </div>
      </div>
    </div>
  )
}

export default GoogleMapEmbed
