/** @jsx jsx */
import { jsx } from "theme-ui"
import { FiArrowRightCircle as IconDescriptionElement } from "react-icons/fi"
import { FiPhoneCall as IconContactUs } from "react-icons/fi"
import site from "../util/site.json"
import { graphql } from "gatsby" // to query for image data
import { GatsbyImage } from "gatsby-plugin-image" // to take image data and render it

import Layout from "../components/layout"

import Services from "../components/services"
import ServiceLocations from "../components/serviceLocations"
import GoogleMapEmbed from "../components/googleMapEmbed"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { mobileUrl, title, tagline } = site.meta
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""
  return (
    <Layout>
      <div className="home-banner grids col-1 sm-2">
        <div>
          <h1 className="title">{title}</h1>
          <p
            className="tagline"
            sx={{
              color: "muted",
            }}
          >
            {tagline}
            <br />
            20 dakikada motorlu servis
          </p>
          <div className="description">
            <IconDescriptionElement
              color="orange"
              style={{ padding: "4px 4px 0px 0px" }}
            />
            <p style={{ display: "inline" }}>
              Firmamız 1968 de Erenköy Ethem Efendi de kurulmuş olup bugüne
              kadar sizlerle hizmet vermektedir.
            </p>
            <br />
            <IconDescriptionElement
              color="orange"
              style={{ padding: "4px 4px 0px 0px" }}
            />
            <p style={{ display: "inline" }}>
              İş yerimiz İstanbul Anahtarcılar ve Çilingirler odası üyesidir. 
              - İşyeri No:68 -
            </p>
          </div>
          <a href={mobileUrl} className="button -contactus">
            <span>
              <IconContactUs className="icon -contactus" color="black" />
            </span>
            Bize Ulaşın
          </a>
        </div>
        <div>
          {Image ? (
            <GatsbyImage
              image={Image}
              alt={title + " - Featured image"}
              className="featured-image"
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <Services />
      <ServiceLocations />
      <GoogleMapEmbed />
    </Layout>
  )
}

export default HomePage
